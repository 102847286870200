import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Menu,
  Row,
  Table,
  Typography,
  Carousel,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import SideDrawer from "../../Components/SideDrawer";
import { AiOutlineDown } from "react-icons/ai";
import moment from "moment";
import {
  BASE_URL,
  downloadEventLogReport,
  getDashboardCounts,
  getDashboardTiles,
  getEmailReport,
  getLogsTableData,
  getPublisherAIMDashboard,
  getPublisherSettings,
  UploadEmailFile,
} from "../../Utils/ApiActions";
import {
  bulkUploadFileStatus,
  eventLogCodesToString,
  nFormatter,
} from "../../Utils/Helper";
import { AiOutlineCheck } from "react-icons/ai";
import { CSVLink } from "react-csv";
import locale from "antd/es/locale/en_GB";
import { useJsonToCsv } from "react-json-csv";
import { ViewApiDocsModal } from "../../Components/ViewAPIDocsModal";
import { AppSelectionDropdown } from "../Connections/components/AppSelectionDropdown";

function DashboardTile({ dashboardTiles }) {
  const [selectedTile, setSelectedTile] = useState(undefined);
  const counterRef = useRef({
    value: 0,
  });

  useEffect(() => {
    setSelectedTile(dashboardTiles?.[0] ?? undefined);
  }, [dashboardTiles]);

  useEffect(() => {
    if (dashboardTiles.length > 0) {
      setInterval(() => {
        counterRef.current.value =
          (counterRef.current.value + 1) % dashboardTiles.length;
        setSelectedTile(dashboardTiles[counterRef.current.value]);
      }, 3000);
    }
  }, [dashboardTiles]);

  function renderMovingCarousel() {
    if (dashboardTiles.length > 0) {
      return (
        <Carousel dots={false} autoplay autoplaySpeed={5000}>
          {dashboardTiles.map((item) => {
            return (
              <>
                <div style={{}}>
                  <Typography.Text
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: 20,
                    }}
                  >
                    {item?.title ?? ""}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    minHeight: "60px",
                  }}
                >
                  <Typography.Text className="dashboard-tile-subtitle">
                    {item?.subTitle ?? ""}
                  </Typography.Text>
                </div>
                <Button
                  onClick={() => {
                    window.open(item.link, "_blank");
                  }}
                  className="fontSizeText"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 35,
                    marginTop: 48,
                    background: "transparent",
                    padding: 8,
                    border: "0px solid transparent",
                    backgroundColor: "black",
                  }}
                >
                  <label
                    className="font-twelve"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: 12,
                    }}
                  >
                    {item?.buttonText ?? "Click here"}
                  </label>
                </Button>
              </>
            );
          })}
        </Carousel>
      );
    }
    return <></>;
  }

  return (
    <div
      style={{
        minHeight: 275,
        // width: 320,
        background: "#26282A",
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 16,
        position: "relative",
      }}
    >
      <img
        className="dashboard-tile-background-logo"
        alt="back"
        src={require("../../assets/dashboard_logomark.png")}
      />
      <div className="dashboard-tile-animate-opacity">
        {renderMovingCarousel()}
      </div>
    </div>
  );
}

function AnalyticsCountCard(props) {
  const trendCount =
    parseInt(props?.count?.total?.toString?.() ?? "0") -
    parseInt(props?.count?.trend?.toString?.() ?? "0");
  if (!props.isEnabled) {
    return (
      <Col sm={12} xs={24} lg={5} xxl={5}>
        <div className={props.className}>
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 24,
            }}
          >
            <div
              onClick={() => props?.onClick?.()}
              style={{
                background: "#171717",
                border: "1px solid #3B3D43",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: 16,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
                cursor: "pointer",
              }}
            >
              Enable Now
            </div>
          </div>
          <div
            style={{
              marginTop: 14,
            }}
          />
          <span
            style={{
              fontSize: 14,
              fontWeight: "700",
              fontFamily: "Montserrat",
              color: "white",
              marginLeft: 24,
              marginTop: 14,
            }}
          >
            {props.title}
          </span>
        </div>
      </Col>
    );
  }
  return (
    <Col sm={12} xs={24} lg={5} xxl={5}>
      <div className={props.className}>
        {/* <Typography.Title className="dashboard-analytics-counts">0</Typography.Title> */}
        <Row className="dashboard-analytics-counts" justify="start">
          <Col>
            <Typography.Title
              className="dashboard-actual-counts"
              style={{ color: "#2E70D2" }}
            >
              {props?.dellimeter === "%"
                ? (props.count?.total?.toFixed?.(2) ?? 0) + "%"
                : nFormatter(props.count?.total ?? 0) +
                (props?.dellimeter ?? "")}
            </Typography.Title>
          </Col>
          <Col>
            <Row className="dashboard-analytics-counts-change-text" align={trendCount === 0 ? "middle" : "top"}>
              {trendCount > 0 ? (
                <Col style={{ marginLeft: 12, height: 0,}}>
                  <img
                    style={{ width: 10, marginBottom: 6  }}
                    src={require("../../assets/up-trend.png")}
                  ></img>
                </Col>
              ) : trendCount < 0 ? (
                <Col style={{ display: "inline", marginLeft: 12, height: 0 }}>
                  <img
                    style={{ width: 10,marginBottom: 6 }}
                    src={require("../../assets/down-trend.png")}
                  ></img>
                </Col>
              ) : (
                <Col
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  marginLeft: 12,
                }}
              >
                <span
                  style={{
                    color: "#8B8B8B",
                    fontSize: 22, // Increase font size for width
                    lineHeight: 1, // Adjust line spacing
                    display: "inline-block",
                  }}
                >
                  –
                </span>
              </Col>
              )}
              <Col>
                <Typography.Title
                  className={
                    trendCount > 0
                      ? "dashboard-analytics-counts-change-up"
                      : trendCount < 0
                        ? "dashboard-analytics-counts-change-down"
                        : "dashboard-analytics-counts-change"
                  }
                >
                  {(trendCount?.toFixed?.(2) ?? 0) + (props.dellimeter ?? "")}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
        <Typography.Title className="dashboard-analytics-box-titles">
          {props.title}
        </Typography.Title>
      </div>
    </Col>
  );
}

function ViewOption(props) {
  return (
    <>
      <AiOutlineCheck
        color={
          props.viewMoreOptions.includes(props.value) ? "#61CEF7" : "#353738"
        }
      ></AiOutlineCheck>
      <Typography.Text
        className={
          props.viewMoreOptions.includes(props.value)
            ? "active-view-option"
            : "inactive-view-option"
        }
        style={{ fontSize: 14, color: "#fff" }}
        onClick={() => {
          props.onClickOption(props.value);
        }}
      >
        {props.label}
      </Typography.Text>
    </>
  );
}

function Dashboard(props) {
  moment.locale("en-gb", {
    week: { dow: 1 },
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthsShort: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  });

  const { saveAsCsv } = useJsonToCsv();
  const inputFile = useRef(null);
  const csvLinkRef = useRef(null);
  const dateFormat = "dddd MM/DD";
  const [counts, setCounts] = useState({
    accountDeteted: { total: 0, trend: 0 },
    clusterSync: { total: 0, trend: 0 },
    emailValidation: { total: 0, trend: 0 },
    userLogin: { total: 0, trend: 0 },
    userSignUp: { total: 0, trend: 0 },
  });
  const [displayLoading, setDisplayLoading] = useState(false);
  const [logsPage, setLogsPage] = useState(1);
  const [emailPage, setEmailPage] = useState(1);
  const [totalLogsPages, setTotalLogsPages] = useState(1);
  const [totalEmailPages, setTotalEmailPages] = useState(1);
  const [logstableData, setLogsTableData] = useState([]);
  const [emailTableData, setEmailTableData] = useState([]);
  const [fromDate, setFromDate] = useState(moment().add(-7, "days").valueOf());
  const [toDate, setToDate] = useState(moment().valueOf());
  const [emailFileForUpload, setEmailFileForUpload] = useState();
  const [viewMoreOptions, setViewMoreOptions] = useState([]);
  const [currentRefreshTime, setCurrentRefreshTime] = useState(
    moment().format("hh:mm A")
  );
  const [eventLogFromDate, setEventLogFromDate] = useState(
    moment().subtract(7, "days").valueOf()
  );
  const [eventLogToDate, setEventLogToDate] = useState(moment().valueOf());
  const [displayViewOptionModal, setViewOptionModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dashboardTiles, setDashboardTiles] = useState([]);

  const [publisherSettings, setPublisherSettings] = useState({});
  const [publisherSettingsLoading, setPublisherSettingsLoading] =
    useState(false);
  const [dashboardCount, setDashboardCount] = useState({});
  const [appIDs, setAppIDs] = useState([]);
  const [eventLogAppIDs, setEventLogAppIDs] = useState([]);

  useEffect(() => {
    loadDashboardTiles();
    getPublisherSettingCall();
    getDashboardCountCall();
  }, []);

  async function getDashboardCountCall() {
    try {
      const data = await getPublisherAIMDashboard(
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD"),
        appIDs
      );
      if (data.success) {
        setDashboardCount(data.data);
      }
    } catch (error) {
      console.log("Unable to get the dashboard count at the moment");
      console.log(error);
    }
  }

  async function getPublisherSettingCall() {
    try {
      setPublisherSettingsLoading(true);
      const data = await getPublisherSettings();
      if (data.success) {
        setPublisherSettings(data.data);
      }
      setPublisherSettingsLoading(false);
    } catch (error) {
      console.log("Unable to get the publisher settings");
      console.log(error);
      setPublisherSettingsLoading(false);
    }
  }

  async function loadDashboardTiles() {
    try {
      const tilesDataResponse = await getDashboardTiles();
      if (tilesDataResponse.success && tilesDataResponse.data) {
        setDashboardTiles(tilesDataResponse.data);
      }
    } catch (error) {
      console.log(`Unable to load the dashboard tiles at the moment`);
      console.log(error);
    }
  }

  useEffect(() => {
    getDashboardCountCall();
  }, [fromDate, toDate, appIDs]);

  useEffect(() => {
    let viewOptions = viewMoreOptions?.join(",");
    getLogsTableData(eventLogFromDate, eventLogToDate, logsPage, viewOptions, eventLogAppIDs)
      .then((response) => {
        setTotalLogsPages(response.data.totalPages);
        let tableData = [];
        // console.log(response)
        response.data?.events?.map((eventData) =>
          tableData.push({
            date_time: moment(eventData.time / 1).format("MM/DD/YYYY hh:mm A"),
            event: eventLogCodesToString(eventData.eventCode),
            success: eventData.eventStatus,
            user: eventData.lockrEmail,
          })
        );
        // console.log(tableData)
        setLogsTableData(tableData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [eventLogFromDate, eventLogToDate, logsPage, viewMoreOptions, eventLogAppIDs]);

  useEffect(() => {
    setCurrentRefreshTime(moment().format("hh:mm A"));
    getEmailTableData();
  }, [emailPage]);

  const getEmailTableData = () => {
    getEmailReport(fromDate, toDate, emailPage)
      .then((response) => {
        setTotalEmailPages(response.data?.totalPages);
        let _emailData = [];
        response?.data?.report?.map((reportItem) => {
          _emailData.push({
            address: reportItem.originalFileName,
            date_time: moment(reportItem.time / 1).format("MM/DD/YYYY hh:mm A"),
            record: reportItem?.totalRecords,
            giveback: reportItem.machineGeneratedEmail,
            verified: reportItem.acceptedEmails,
            status: reportItem.status,
            unknown: reportItem.unknown,
          });
        });
        setEmailTableData(_emailData);
        // console.log(_emailData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const customFormat = (value) => `${value.format(dateFormat)}`;
  const emailColumns = [
    {
      title: "File Name",
      key: "address",
      dataIndex: "address",
      width: "150px",
      ellipsis: true,
      tootltip: true,
    },
    {
      title: "Date/Time",
      key: "date_time",
      dataIndex: "date_time",
      width: "max-content",
    },
    {
      title: "Record #",
      key: "record",
      dataIndex: "record",
      width: "80px",
      // align: 'center',
      render: (value) => value || 0,
    },
    {
      title: "Verified %",
      key: "verified",
      dataIndex: "verified",
      width: "80px",
      // align: 'center',
      render: (value, rowData) => _renderVerifiedEmails(rowData),
    },
    {
      title: "Machine generated %",
      key: "giveback",
      dataIndex: "giveback",
      width: "150px",
      render: (value, rowData) => _renderMachineGenerated(rowData),
    },
    {
      title: "Unknown %",
      key: "unknown",
      dataIndex: "unknown",
      width: "150px",
      render: (value, rowData) => renderUnknownPercenteage(rowData),
    },
  ];
  const logsColumns = [
    {
      title: "Date/Time",
      key: "date_time",
      dataIndex: "date_time",
      width: "max-content",
    },
    {
      title: "Event",
      key: "event",
      dataIndex: "event",
      width: "max-content",
    },
    {
      title: "Success",
      key: "success",
      dataIndex: "success",
      width: "max-content",
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
      width: "max-content",
    },
  ];

  const _renderVerifiedEmails = (rowData) => {
    if (rowData.status === 0 || rowData.status === 1) {
      return "-";
    } else if (rowData.status === 3) {
      return "FAILED";
    } else {
      return Math.ceil((rowData.verified * 100) / rowData.record) + "%";
    }
  };

  function renderUnknownPercenteage(rowData) {
    if (rowData.status === 0 || rowData.status === 1) {
      return "PROCESSING";
    } else if (rowData.status === 3) {
      //this is failed;
      return "FAILED";
    } else {
      return (
        Math.floor((parseInt(rowData.unknown) * 100) / rowData.record) + "%"
      );
    }
  }

  const _renderMachineGenerated = (rowData) => {
    if (rowData.status === 0 || rowData.status === 1) {
      return "PROCESSING";
    } else if (rowData.status === 3) {
      //this is failed;
      return "FAILED";
    } else {
      return Math.floor((rowData.giveback * 100) / rowData.record) + "%";
    }
  };

  const handleEmailUpload = (file) => {
    UploadEmailFile(file)
      .then((response) => {
        // console.log(response);

        if (response.success == false) {
          setErrorMessage(response.error.message);
        } else {
          setErrorMessage("");
        }
        getEmailTableData();
      })
      .catch((error) => { });
  };

  const onClickOption = (value) => {
    setLogsPage(1);
    if (!viewMoreOptions.includes(value)) {
      let codes = Object.assign([], viewMoreOptions);
      codes.push(value);
      if (value === 1001) {
        codes.push(value + 1);
      }
      setViewMoreOptions(codes);
    } else {
      let codes = Object.assign([], viewMoreOptions);
      codes.splice(codes.indexOf(value), 1);
      if (value === 1001) {
        codes.splice(codes.indexOf(value + 1), 1);
      }
      setViewMoreOptions(codes);
    }
    // setViewOptionModal(false);
  };

  function gotoApiAndServices() {
    props.history.push("/console/apiandservices");
  }

  const renderAnalyticsCountsBoard = () => {
    return (
      <Row className="dashboard-analytics-counts-board-row">
        <AnalyticsCountCard
          onClick={gotoApiAndServices}
          isEnabled={publisherSettings.aimIntegrated}
          className={"dashboard-analytics-counts-board-cols-left"}
          title="Authenticated Users"
          count={{
            trend: dashboardCount.authenticatedHistoryUsers?.count ?? 0,
            total: dashboardCount.authenticatedUsers?.count ?? 0,
          }}
        ></AnalyticsCountCard>
        <AnalyticsCountCard
          onClick={gotoApiAndServices}
          isEnabled={publisherSettings.ssoIntegrated}
          className={"dashboard-analytics-counts-board-cols"}
          title="lockrMail Users"
          count={{
            total: dashboardCount?.lockrMailUsers?.count ?? 0,
            trend: dashboardCount?.lockrMailHistoryUsers?.count ?? 0,
          }}
        ></AnalyticsCountCard>
        <AnalyticsCountCard
          onClick={gotoApiAndServices}
          isEnabled={publisherSettings.aimIntegrated}
          className={"dashboard-analytics-counts-board-cols"}
          title="Alternative IDs"
          count={{
            total: dashboardCount?.alternateIDs?.count ?? 0,
            trend: dashboardCount?.alternateHistoryIDs?.count ?? 0,
          }}
        ></AnalyticsCountCard>
        <AnalyticsCountCard
          onClick={gotoApiAndServices}
          isEnabled={publisherSettings.aimIntegrated}
          dellimeter="%"
          className={"dashboard-analytics-counts-board-cols"}
          title="Returning Users"
          count={{
            total: dashboardCount?.totalReturningUsersPercentage ?? 0,
            trend: dashboardCount?.totalReturningUsersHistoryPercentage ?? 0,
          }}
        ></AnalyticsCountCard>
        <AnalyticsCountCard
          onClick={gotoApiAndServices}
          isEnabled={publisherSettings.emailVerificationIntegrated}
          className={"dashboard-analytics-counts-board-cols-right"}
          title="MGEs Detected"
          count={{
            total: dashboardCount?.rejectedEmailsCount?.count ?? 0,
            trend: dashboardCount?.rejectedEmailHistoryCount?.count ?? 0,
          }}
        ></AnalyticsCountCard>
      </Row>
    );
  };
  const menu = (
    <div className="view-options-dropdown">
      <Typography.Text style={{ fontSize: 14, color: "#fff" }}>
        Date
      </Typography.Text>
      <Row style={{ marginTop: "9px", alignItems: "center" }}>
        <ConfigProvider locale={locale}>
          <DatePicker
            getPopupContainer={(trigger) => trigger.parentNode}
            disabledDate={(date) => moment(date) > moment()}
            value={moment(eventLogFromDate)}
            onChange={(e) => {
              setEventLogFromDate(moment(e).valueOf());
            }}
            format={customFormat}
            className="dashboard-date-picker-event-log"
            dropdownClassName="calender-date-picker"
            popupStyle={{ background: "#171717" }}
            suffixIcon={
              <AiOutlineDown
                color="#fff"
                style={{ cursor: "pointer" }}
              ></AiOutlineDown>
            }
            allowClear={false}
          ></DatePicker>
          <Typography.Text className="datepicker-btw-to-view-options">
            to
          </Typography.Text>
          <DatePicker
            getPopupContainer={(trigger) => trigger.parentNode}
            disabledDate={(date) =>
              moment(date) > moment() || moment(eventLogFromDate) > moment(date)
            }
            value={moment(eventLogToDate)}
            onChange={(e) => {
              setEventLogToDate(moment(e).valueOf());
            }}
            format={customFormat}
            className="dashboard-date-picker-event-log"
            dropdownClassName="calender-date-picker"
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          ></DatePicker>
        </ConfigProvider>
      </Row>
      <div className="view-option-divider"></div>
      <Typography.Text style={{ fontSize: 14, color: "#fff" }}>
        Categories
      </Typography.Text>
      <Row style={{ marginTop: 12, color: "white" }}>
        <Col xs={24}>
          <Row>
            <Col>
              <AiOutlineCheck
                color={viewMoreOptions.length === 0 ? "#61CEF7" : "#353738"}
              ></AiOutlineCheck>{" "}
              <Typography.Text
                onClick={() => {
                  setViewMoreOptions([]);
                  setLogsPage(1);
                }}
                className={
                  viewMoreOptions.length === 0
                    ? "active-view-option"
                    : "inactive-view-option"
                }
                style={{ fontSize: 14 }}
              >
                All
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={1001}
                label={"Signin"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                value={1003}
                label={"Consent"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={1004}
                label={"2FA"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                value={1005}
                label={"Signup"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={1006}
                label={"Profile"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                value={1007}
                label={"Sync"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={1008}
                label={"Email verify"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                value={1013}
                label={"Delete"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={2001}
                label={"UID2"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                value={2002}
                label={"ID5"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                value={2003}
                label={"RAMPID ATS"}
                viewMoreOptions={viewMoreOptions}
                onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                  value={6050}
                  label={"RAMP ID RTIS"}
                  viewMoreOptions={viewMoreOptions}
                  onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
          <Row justify="space-between" style={{marginTop: 8}}>
            <Col xs = {12}>
              <ViewOption value = {7001} label={"Panorama ID"} viewMoreOptions={viewMoreOptions} onClickOption={onClickOption}/>
            </Col>
            <Col xs = {12}>
              <ViewOption value = {7000} label={"Panorama ID Loaded"} viewMoreOptions={viewMoreOptions} onClickOption={onClickOption}/>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 8 }}>
            <Col xs={12}>
              <ViewOption
                  value={6060}
                  label={"Yahoo Connect ID"}
                  viewMoreOptions={viewMoreOptions}
                  onClickOption={onClickOption}
              ></ViewOption>
            </Col>
            <Col xs={12}>
              <ViewOption
                  value={6001}
                  label={"FIRSTID"}
                  viewMoreOptions={viewMoreOptions}
                  onClickOption={onClickOption}
              ></ViewOption>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ marginTop: 20 }} />
      <Typography.Text style={{ marginTop: 20, fontSize: 14, color: "#fff" }}>
        Data Endpoints
      </Typography.Text>
      <Row justify="space-between" style={{ marginTop: 8 }}>
        <Col xs={12}>
          <ViewOption
            value={2004}
            label={"AWS"}
            viewMoreOptions={viewMoreOptions}
            onClickOption={onClickOption}
          ></ViewOption>
        </Col>
        <Col xs={12}>
          <ViewOption
            value={2005}
            label={"Optable"}
            viewMoreOptions={viewMoreOptions}
            onClickOption={onClickOption}
          ></ViewOption>
        </Col>
      </Row>
    </div>
  );

  const _downloadEventReport = () => {
    let viewOptions = viewMoreOptions?.join(",");
    downloadEventLogReport(eventLogFromDate, eventLogToDate, viewOptions)
      .then((data) => {
        let _reportData = [];
        data?.data?.map((dataItem) => {
          _reportData.push({
            time: moment(parseFloat(dataItem.time)).format(
              "MM/DD/yyyy hh:mm A"
            ),
            event: eventLogCodesToString(dataItem.eventCode),
            success: dataItem.eventStatus,
            user: dataItem.lockrEmail,
          });
        });
        saveAsCsv({
          data: _reportData,
          fields: {
            time: "Date/Time",
            event: "Event",
            success: "Success",
            user: "User",
          },
          filename: "event-log-report",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderLogsTable = () => {
    return (
      <div style={{ backgroundColor: "black", marginRight: 10 }}>
        <Row justify="space-between" style={{ background: "black" }}>
          <Col sm={6}>
            <div style={{ height: 64, width: "fit-content" }}>
              <Typography.Title className="dashboard-table-heading">
                Logs
              </Typography.Title>
            </div>
          </Col>
          <Col sm={18} style={{ display: 'flex', justifyContent: 'flex-end', textAlign: "end" }}>
            <div style={{ marginTop: 20 }}>
              <AppSelectionDropdown value={eventLogAppIDs} onChange={appIDs => { setEventLogAppIDs(appIDs) }} size="small" />
            </div>
            <Dropdown
              visible={displayViewOptionModal}
              onClick={() => {
                setViewOptionModal(true);
              }}
              destroyPopupOnHide={false}
              overlay={menu}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Button
                className="dashboard-view-more-options"
                style={{ height: "29px", marginRight: 8, marginTop: 20 }}
                type="primary"
                shape="default"
              >
                View options{" "}
                <AiOutlineDown
                  style={{ marginLeft: 12, paddingTop: 4 }}
                ></AiOutlineDown>
              </Button>
            </Dropdown>
            <Button
              className="dashboard-download-btn"
              style={{ height: "29px", marginRight: 24 }}
              onClick={() => {
                _downloadEventReport();
              }}
              type="primary"
              shape="default"
            >
              <img
                alt={"download"}
                src={require("../../assets/download-icon.png")}
                className="download-icon"
              ></img>{" "}
              Download
            </Button>
          </Col>
        </Row>
        <Row style={{ border: "2px solid #1D1D1D", height: 0 }}></Row>
        <Row style={{ paddingLeft: 24 }}>
          <Col xs={24}>
            <Table
              scroll={{ x: "max-content" }}
              style={{ overflowWrap: "anywhere" }}
              className="dashboard-tables"
              rowKey={"id"}
              loading={displayLoading}
              pagination={{
                current: logsPage,
                defaultCurrent: 1,
                defaultPageSize: 8,
                total: 8 * totalLogsPages,
                onChange: (value) => setLogsPage(value),
              }}
              dataSource={logstableData}
              columns={logsColumns}
            ></Table>
          </Col>
        </Row>
      </div>
    );
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onDownloadTemplateClick = () => {
    // csvLinkRef.current.link.click();
  };

  const renderEmailTable = () => {
    return (
      <div style={{ backgroundColor: "black", marginLeft: 10 }}>
        <Row justify="space-between" style={{ background: "black" }}>
          <Col sm={10}>
            <div style={{ height: 64 }}>
              <Typography.Title className="dashboard-table-heading">
                Email uploads
              </Typography.Title>
            </div>
          </Col>
          <Col sm={14} style={{ textAlign: "end" }}>
            <a
              href={
                BASE_URL +
                "/publisher/docs/lockr-email-domain-verification.xlsx"
              }
              target="_blank"
            >
              <Button
                className="dashboard-view-more-options"
                style={{ height: "29px", marginRight: 12 }}
                onClick={() => onDownloadTemplateClick()}
                type="primary"
              >
                {/* <img src={require('../../assets/download-icon.png')} className="download-icon"></img>  */}
                Template
                {/* <CSVLink filename='lockr-email-domain-verification.csv' ref={csvLinkRef} data={[
                                ["Please specify email address or domain to be verified in separate cell of column A as shown below.\nNotes:\n1 Minimum required rows: 1000.\n2. Please use a separate cell for each record. If only providing domains for verification, accurate results can only be provided if a row is provided per authentication (even if from the same domain). Otherwise, the percentage of machine generated sign ups will not be accurate."],
                                ["Email/Domain"],
                                ["john.smith@gmail.com"],
                                ["john@yahoo.com"],
                                ["robert@rediffmail.com"],
                                ["john@rediffmail.com"],
                                ["john.doe@gmail.com"],
                                ["albert@yahoo.com"],
                                ["jessica@yahoo.com"],
                                ["gmail.com"],
                                ["yahoo.com"],
                                ["rediffmail.com"],
                                ["smith@gmail.com"],
                                ["anna@gmail.com"],
                                ["icloud.com"]
                            ]}></CSVLink> */}
              </Button>
            </a>
            <Button
              className="dashboard-download-btn"
              style={{ height: "29px", marginRight: 24 }}
              onClick={() => onButtonClick()}
              type="primary"
            >
              <img
                src={require("../../assets/upload-icon.png")}
                className="download-icon"
              ></img>{" "}
              Upload
              <input
                value={""}
                onChange={(event) => {
                  handleEmailUpload(event.target.files[0]);
                }}
                type="file"
                accept={".csv,.xlsx,.xls"}
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
            </Button>

            <Row
              className="errorText"
              style={{ justifyContent: "end", paddingRight: 22 }}
            // style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#f46666', paddingLeft: 70 }}
            >
              {errorMessage}
            </Row>
          </Col>
        </Row>
        <Row style={{ border: "2px solid #1D1D1D", height: 0 }}></Row>
        <Row style={{ paddingLeft: 24 }}>
          <Col xs={24}>
            <Table
              scroll={{ x: "max-content" }}
              className="dashboard-tables"
              rowKey={"id"}
              loading={displayLoading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 8,
                total: 8 * totalEmailPages,
                onChange: (value) => setEmailPage(value),
              }}
              dataSource={emailTableData}
              columns={emailColumns}
            ></Table>
          </Col>
        </Row>
      </div>
    );
  };

  const renderTables = () => {
    return (
      <Row
        className="rowGap"
        justify="space-around"
        style={{
          marginLeft: 91,
          marginRight: 91,
          marginTop: 40,
          flexFlow: "unset",
          overflow: "hidden",
          gap: "26px",
        }}
      >
        <div
          className="logsTable"
          style={{ minWidth: "78%" }}
          sm={19}
          xs={19}
          lg={19}
          xxl={19}
        >
          {renderLogsTable()}
        </div>
        <div sm={5} xs={5} lg={5} xxl={5} className="dashboardTile">
          <DashboardTile dashboardTiles={dashboardTiles} />
        </div>
        {/* <Col sm={12} xs={12} xxl={12} lg={12}>{renderEmailTable()}</Col> */}
      </Row>
    );
  };

  const _getUpdatedDate = () => {
    if (moment(toDate).isValid()) {
      if (moment(toDate).isSame(moment(), "date")) {
        return "As of today " + moment(toDate).format("hh:mm A");
      } else {
        return "As of " + moment(toDate).format("MM/DD/YYYY");
      }
    } else {
      return "As of Today " + currentRefreshTime;
    }
  };

  const renderHeading = () => {
    return (
      <Row
        justify="space-between"
        align="middle"
        className="identity-suite-dashboard-heading"
      >
        <Col sm={12} xs={24}>
          <Typography.Title className="dashboard-title">
            {localStorage.getItem("publisherName") || "Publisher"} Dashboard
          </Typography.Title>
          <label
            style={{ fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B" }}
            className="dashboard-as-of-today"
          >
            {_getUpdatedDate()}
          </label>
        </Col>
        <Col sm={12} xs={24} style={{ textAlign: "end", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <AppSelectionDropdown disabled={false} value={appIDs} onChange={appIDs => { setAppIDs(appIDs) }} />
          <ConfigProvider locale={locale}>
            <DatePicker
              disabledDate={(date) => moment(date) > moment()}
              value={moment(fromDate)}
              onChange={(e) => {
                setFromDate(moment(e).valueOf());
              }}
              format={customFormat}
              className="dashboard-date-picker"
              dropdownClassName="calender-date-picker"
              popupStyle={{ background: "#000" }}
              suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
              allowClear={false}
            ></DatePicker>
            <Typography.Text className="datepicker-btw-to">to</Typography.Text>
            <DatePicker
              disabledDate={(date) =>
                moment(date) > moment() || moment(fromDate) > moment(date)
              }
              value={moment(toDate)}
              onChange={(e) => {
                setToDate(moment(e).valueOf());
              }}
              format={customFormat}
              className="dashboard-date-picker"
              dropdownClassName="calender-date-picker"
              suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
              allowClear={false}
            ></DatePicker>
          </ConfigProvider>
        </Col>
      </Row>
    );
  };

  const _renderViewOptionBackDrop = () => {
    return (
      <div
        onClick={() => {
          setViewOptionModal(false);
        }}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          backgroundColor: "transparent",
        }}
      ></div>
    );
  };

  function GetStartedButton() {
    if (!publisherSettingsLoading) {
      return (
        <div
          onClick={() => {
            props.history.push("/console/apiandservices");
          }}
          style={{
            height: 50,
            width: "60%",
            background: "#61CEF7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: 16,
              color: "#000000",
              cursor: "pointer",
            }}
          >
            Get Started
          </span>
        </div>
      );
    }
    return <></>;
  }

  function LinkedButton(title, onPress) {
    return (
      <div
        onClick={onPress}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 16,
          height: 50,
          width: "60%",
          border: "1px solid #50E8B1",
          gap: 8,
          cursor: "pointer",
        }}
      >
        <img
          style={{
            height: 24,
            width: 24,
          }}
          alt="enabled-check"
          src={require("../../assets/enabled_check.png")}
        />
        <span
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: 16,
            color: "#50E8B1",
            cursor: "pointer",
          }}
        >
          {title}
        </span>
      </div>
    );
  }

  function renderPublisherEnabledSettings() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: 90,
          paddingRight: 90,
          justifyContent: "space-between",
          marginTop: 46,
          gap: 60,
        }}
      >
        <div
          style={{
            height: 400,
            flex: 1,
            background: "#353738",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              margin: 4,
              width: "98%",
              height: 200,
              borderRadius: 8,
              background: "#171717",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/aim_ability.png")}
              alt="aim-ability"
              style={{
                margin: 4,
                width: "380px",
                maxHeight: 200,
              }}
            />
          </div>
          <span
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "700",
              marginTop: 24,
              fontFamily: "Montserrat",
            }}
          >
            Alternative Identity Manager
          </span>
          <span
            style={{
              color: "white",
              opacity: 0.5,
              fontWeight: "400",
              fontSize: 14,
              fontFamily: "Montserrat",
              marginTop: 8,
              maxWidth: 288,
              textAlign: "center",
            }}
          >
            Seamlessly test and integrate alternative identity providers.
          </span>
          {!publisherSettings.aimIntegrated
            ? GetStartedButton()
            : LinkedButton("Analytics", () => {
              props.history.push("/console/analytics");
            })}
        </div>
        <div
          style={{
            height: 400,
            flex: 1,
            background: "#353738",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              margin: 4,
              width: "98%",
              height: 200,
              borderRadius: 8,
              background: "#171717",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/email_validation_ability.png")}
              alt="aim-ability"
              style={{
                maxWidth: "380px",
                maxHeight: 200,
              }}
            />
          </div>
          <span
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "700",
              fontFamily: "Montserrat",
              marginTop: 24,
            }}
          >
            Email Validation API
          </span>
          <span
            style={{
              color: "white",
              opacity: 0.5,
              fontWeight: "400",
              fontSize: 14,
              fontFamily: "Montserrat",
              maxWidth: 288,
              textAlign: "center",
              marginTop: 8,
            }}
          >
            Re-engage visitors attempting to circumvent registration walls.
          </span>
          {!publisherSettings.emailVerificationIntegrated
            ? GetStartedButton()
            : LinkedButton("Settings", () => {
              props.history.push("/console/apiandservices");
            })}
        </div>
        <div
          style={{
            height: 400,
            flex: 1,
            background: "#353738",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              margin: 4,
              width: "98%",
              height: 200,
              borderRadius: 8,
              background: "#171717",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/sso_ability.png")}
              alt="aim-ability"
              style={{
                maxWidth: "380px",
              }}
            />
          </div>
          <span
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "700",
              fontFamily: "Montserrat",
              marginTop: 24,
            }}
          >
            Sign in with lockrMail
          </span>
          <span
            style={{
              color: "white",
              opacity: 0.5,
              fontWeight: "400",
              fontSize: 14,
              fontFamily: "Montserrat",
              marginTop: 8,
              maxWidth: 288,
              textAlign: "center",
            }}
          >
            Authenticate users with consented and verified first-party data.
          </span>
          {!publisherSettings.ssoIntegrated
            ? GetStartedButton()
            : LinkedButton("Analytics", () => {
              props.history.push("/console/analytics");
            })}
        </div>
      </div>
    );
  }

  return (
    <SideDrawer>
      {renderHeading()}
      {renderAnalyticsCountsBoard()}
      <Row
        style={{
          border: "1px #26282A solid",
          marginTop: 46,
          marginLeft: 90,
          marginRight: 90,
        }}
      ></Row>
      {renderTables()}
      {renderPublisherEnabledSettings()}
      {displayViewOptionModal && _renderViewOptionBackDrop()}
      <ViewApiDocsModal />
    </SideDrawer>
  );
}

export default withRouter(Dashboard);
