import { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import { Button, Checkbox, Col, Row, Typography } from "antd";
import {
  DataEndpointAWSAccessDropDown,
  DataEndpointTitleInput,
  DataEndpointTitleValue,
  DataEndpointTypeDropDown,
  FrequencyDropDown,
  FullRefreshNoticeModal,
  ReturnToConnectionHubModal,
} from "./AWSDataEndpoint";
import { RotatingLines } from "react-loader-spinner";
import {
  DATA_ENDPOINTS,
  mapSnowflakStatusToUI,
  mapSnowflakeStatusToColor,
} from "../../../Utils/Helper";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";
import { TitleText } from "../../../Components/NewAppModal";
import {
  dataEndpointFullRefresh,
  deleteAWSEndpoint,
  getExternalID,
  getS3EndPoints,
  newS3DataEndPoint,
  updateS3DataEndPoint,
} from "../../../Utils/ApiActions";
import { FullRefreshLoader } from "./components/full-refresh-loader";
import { useHistory } from "react-router-dom";
import { AppSelectionDropdown } from "./components/AppSelectionDropdown";

export function OptableDataEndpoint() {
  const defaultConnectionDetails = {
    s3Bucket: "",
    externalId: "",
    roleARN: "",
    sessionName: "",
    region: "",
    fileName: "",
    frequency: "Daily",
    type: "Incremental",
    name: "",
    uid2Enabled: false,
    id5Enabled: false,
    liverampEnabled: false,
    firstPartyCookies: false,
    accessType: "AWS IAM",
    domains: [],
    appIDs: [],
    accessKey: "",
    secretKey: "",
    dateCreatedEnabled: false,
    firstIdEnabled: false,
    firstIdEnabledHistory: false,
  };

  const [displayLoading, setDisplayLoading] = useState(false);

  const [awsConnections, setAWSConnections] = useState([]);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [formMode, setFormMode] = useState("NEW");
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [showFullRefreshModal, setShowFullRefreshModal] = useState(undefined);
  const [displayFullRefreshLoading, setDisplayFullRefreshLoading] =
    useState(false);
  const [selectedConnectionToDelete, setSelectedConnectionToDelete] =
    useState(undefined);

  const [newConnectionDetails, setNewConnectionDetails] = useState(
    defaultConnectionDetails
  );

  const [showFullRefreshIndicator, setShowFullRefreshIndicator] =
    useState(false);

  const [selectedConnectionDetails, setSelectedConnectionDetails] =
    useState(undefined);

  const [externalId, setExternalId] = useState("");

  const history = useHistory();

  const selectedConnectionConfiguration = useMemo(() => {
    try {
      return JSON.parse(selectedConnectionDetails.configuration);
    } catch (error) {
      console.log("Unable to set the configurations");
      console.log(error);
    }
  }, [selectedConnectionDetails]);

  useEffect(() => {
    getS3EndpointsCall();
    getExternalIDCall();
    setInterval(() => {
      getS3EndpointsCall(true);
    }, 60000);
  }, []);

  async function getExternalIDCall() {
    try {
      const response = await getExternalID();
      if (response?.success) {
        setExternalId(response?.data);
        setNewConnectionDetails({
          ...newConnectionDetails,
          externalId: response?.data,
        });
      }
    } catch (error) {
      console.log("Unable to get the external id for the publisher");
      console.log(error);
    }
  }

  async function getS3EndpointsCall(dontChangeApp = false) {
    try {
      setDisplayLoading(true);
      const data = await getS3EndPoints(DATA_ENDPOINTS.OPTABLE);
      if (data.success) {
        setAWSConnections(data.data);
        !dontChangeApp && setSelectedConnectionDetails(data?.data?.[0]);
      }
    } catch (error) {
      console.log("Unable to get the S3 endpoints");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function updateS3EndPointCall(reqBody, id) {
    setDisplayLoading(true);
    try {
      await updateS3DataEndPoint(reqBody, id, DATA_ENDPOINTS.OPTABLE);
      setShowNewConnection(false);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to update the endpoint details at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function newS3DataEndPointCall(reqBody) {
    setDisplayLoading(true);
    try {
      const { data } = await newS3DataEndPoint(reqBody, DATA_ENDPOINTS.OPTABLE);
      setSelectedConnectionDetails(data);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to create new data endpoint at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  function onSubmitButtonClick() {
    const details = {
      ...newConnectionDetails,
      externalId:
        formMode === "NEW" ? externalId : newConnectionDetails.externalId,
    };
    if (formMode === "EDIT") {
      updateS3EndPointCall(details, selectedConnectionDetails.id);
      setFormMode("NEW");
      setSelectedRecord(undefined);
      setNewConnectionDetails(defaultConnectionDetails);
      return;
    }
    newS3DataEndPointCall(details);
    setShowNewConnection(false);
    setNewConnectionDetails(defaultConnectionDetails);
  }

  function renderNewDataEndpointForm() {
    return (
      <div
        style={{
          background: "black",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingTop: 24,
          paddingBottom: 24,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleText
            value={newConnectionDetails?.name}
            onChange={(event) => {
              setNewConnectionDetails({
                ...newConnectionDetails,
                name: event.target.value,
              });
            }}
            placeholder="Connection Name"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              disabled={!checkNewFormValidation()}
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              {formMode === "EDIT" ? "Save" : "Submit"}
            </Button>
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                setShowNewConnection(false);
              }}
            >
              Cancel
            </Button>
            <img
              alt="delete"
              onClick={() => {
                setSelectedConnectionToDelete(selectedConnectionDetails.id);
              }}
              src={require("../../../assets/remove_icon.png")}
              style={{
                marginLeft: 16,
                marginRight: 16,
                cursor: "pointer",
                width: 18,
                height: 20,
              }}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Connection Settings
          </span>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <DataEndpointTitleInput
              title={"AWS Access Type"}
              infoText={
                "lockr recommends using AWS IAM roles for its enhanced security."
              }
              customInput={
                <DataEndpointAWSAccessDropDown
                  value={newConnectionDetails.accessType ?? "AWS IAM"}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      accessType: event,
                    });
                  }}
                />
              }
            />
          </div>
          {/**add here */}
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Role ARN"}
                  value={newConnectionDetails.roleARN}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      roleARN: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"External ID"}
                  value={
                    formMode === "EDIT"
                      ? newConnectionDetails?.externalId
                      : externalId
                  }
                  disabled
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      externalId: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Session Name"}
                  value={newConnectionDetails.sessionName}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      sessionName: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {newConnectionDetails.accessType === "AWS Access Key" && (
            <div style={{ marginTop: 20 }}>
              <DataEndpointTitleInput
                title={"Access Key"}
                value={newConnectionDetails.accessKey}
                onChange={(event) => {
                  setNewConnectionDetails({
                    ...newConnectionDetails,
                    accessKey: event.target.value,
                  });
                }}
              />
            </div>
          )}
          {newConnectionDetails.accessType === "AWS Access Key" && (
            <div style={{ marginTop: 20 }}>
              <DataEndpointTitleInput
                title={"Secret Key"}
                value={newConnectionDetails.secretKey}
                onChange={(event) => {
                  setNewConnectionDetails({
                    ...newConnectionDetails,
                    secretKey: event.target.value,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Bucket Settings
          </span>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Bucket"}
              value={newConnectionDetails.s3Bucket}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  s3Bucket: event.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Region"}
              value={newConnectionDetails.region}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  region: event.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"File Name"}
              value={newConnectionDetails.fileName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  fileName: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Delivery Settings
          </span>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Delivery Type"}
              customInput={
                <DataEndpointTypeDropDown
                  value={newConnectionDetails.type}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      type: event,
                      frequency:
                        newConnectionDetails.frequency === "Hourly" &&
                          event === "Complete Dump"
                          ? "Daily"
                          : newConnectionDetails.frequency,
                    });
                  }}
                />
              }
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Delivery Frequency"}
              customInput={
                <FrequencyDropDown
                  hideYearly
                  hideHourly={newConnectionDetails.type === "Complete Dump"}
                  value={newConnectionDetails.frequency}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      frequency: event,
                    });
                  }}
                />
              }
            />
          </div>
        </div>

        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <AppSelectionDropdown value={newConnectionDetails?.appIDs} onChange={appIDs => {
            setNewConnectionDetails(prevState => ({
              ...prevState,
              appIDs
            }))
          }}
            domainsValue={newConnectionDetails?.domains}
            onDomainChange={domains => {
              setNewConnectionDetails(prevState => ({
                ...prevState,
                domains
              }))
            }}
          />
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Attributes
          </span>
          <span
            style={{
              marginTop: 16,
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: 12,
              color: "#8B8B8B",
            }}
          >
            Select the attributes to include for this connection with the
            endpoint.
          </span>
          <div
            style={{
              marginTop: 32,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.firstName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstName: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First Name
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.lastName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  lastName: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Last Name
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.rawEmail}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  rawEmail: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Email
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.sha256}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  sha256: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Email Hash
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.uid2Enabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  uid2Enabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              UID2 (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.uid2EnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  uid2EnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              UID2 (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.id5Enabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  id5Enabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ID5 (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.id5EnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  id5EnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ID5 (history)
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.liverampEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  liverampEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ATS (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.liverampEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  liverampEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ATS (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.euidEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  euidEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              EUID (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.euidEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  euidEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              EUID (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.yahooConnectIdEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  yahooConnectIdEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Yahoo Connect ID (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.yahooConnectIdEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  yahooConnectIdEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Yahoo Connect ID (history)
            </span>
          </div>
          <div style={{ marginTop: 20, paddingLeft: 32 }}>
            <Checkbox
              checked={newConnectionDetails?.firstIdEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstIdEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First ID (latest)
            </span>
          </div>
          <div style={{ marginTop: 20, paddingLeft: 32 }}>
            <Checkbox
              checked={newConnectionDetails?.firstIdEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstIdEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First ID (history)
            </span>
          </div>
          <div style = {{
              marginTop: 20,
              paddingLeft: 32,
          }}>
              <Checkbox
                checked={newConnectionDetails?.panoramaIdEnabled}
                onChange = {event => {
                    setNewConnectionDetails({
                        ...newConnectionDetails,
                        panoramaIdEnabled: event.target.checked,
                    })
                }}
              />
              <span
                style={{
                    color: 'white',
                    fontFamily: 'Montserrat',
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: '500',
                }}
              >
                    Panorama ID (latest)
              </span>
          </div>
          <div style = {{marginTop: 20, paddingLeft: 32}}>
              <Checkbox
                 checked = {newConnectionDetails?.panoramaIdEnabledHistory}
                 onChange = {event => {
                    setNewConnectionDetails({
                        ...newConnectionDetails,
                        panoramaIdEnabledHistory: event.target.checked,
                    })
                 }}
              />
              <span style = {{
                  color: 'white',
                  fontFamily: 'Montserrat',
                  marginLeft: 12,
                  fontSize: 16,
                  fontWeight: '500',
              }}>
                  Panorama ID (history)
              </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.firstPartyCookies}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstPartyCookies: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First Party Cookies
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.successEvent}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  successEvent: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Authentication Method
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.consentString}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  consentString: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              TCF String
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.dateCreatedEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  dateCreatedEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Created At
            </span>
          </div>

        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            style={{ width: "200px" }}
            type="primary"
            size="small"
            className="sign-in"
            disabled={!checkNewFormValidation()}
            onClick={() => {
              onSubmitButtonClick();
            }}
          >
            {formMode === "EDIT" ? "Save" : "Submit"}
          </Button>
        </div>
      </div>
    );
  }

  async function startFullRefresh() {
    try {
      setShowFullRefreshIndicator(true);
      setShowFullRefreshModal(false);
      setDisplayFullRefreshLoading(true);
      selectedConnectionDetails?.id &&
        (await dataEndpointFullRefresh(selectedConnectionDetails.id));
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to do the full refresh at the moment");
      console.log(error);
    }
    setTimeout(() => {
      setDisplayFullRefreshLoading(false);
    }, 10000);
  }

  function checkNewFormValidation() {
    let validation = true;
    if (
      newConnectionDetails?.name?.trim?.() === "" ||
      newConnectionDetails.s3Bucket?.trim?.() === "" ||
      newConnectionDetails?.fileName?.trim?.() === "" ||
      newConnectionDetails?.region?.trim?.() === "" ||
      (newConnectionDetails.accessType === "AWS IAM" &&
        (newConnectionDetails?.roleARN?.trim?.() === "" ||
          newConnectionDetails?.sessionName?.trim?.() === "")) ||
      (newConnectionDetails.accessType === "AWS Access Key" &&
        (!newConnectionDetails?.accessKey?.trim?.() ||
          !newConnectionDetails?.secretKey?.trim?.()))
    ) {
      validation = false;
    }
    return validation;
  }

  function renderMainSections() {
    if (awsConnections.length || showNewConnection) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 90,
            paddingRight: 90,
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: 294,
              display: "flex",
              flex: 1,
              background: "#353738",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div
              style={{
                paddingBottom: 60,
              }}
            >
              {showNewConnection && formMode !== "EDIT" && (
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    minHeight: 60,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    background: "black",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      left: 0,
                      height: "100%",
                      width: 4,
                      background: "#61CEF7",
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 18,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#61CEF7",
                    }}
                  >
                    {newConnectionDetails.name ?? "New Connection"}
                  </span>
                </div>
              )}
              {awsConnections?.map?.((item) => {
                return (
                  <div
                    onClick={() => {
                      setShowNewConnection(false);
                      setSelectedConnectionDetails(item);
                    }}
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      minHeight: 60,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      background:
                        selectedConnectionDetails?.id === item?.id &&
                          (!showNewConnection ||
                            (showNewConnection && formMode === "EDIT"))
                          ? "black"
                          : "#353738",
                      width: "100%",
                    }}
                  >
                    {selectedConnectionDetails?.id === item?.id &&
                      (!showNewConnection ||
                        (showNewConnection && formMode === "EDIT")) && (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            height: "100%",
                            width: 4,
                            background: "#61CEF7",
                          }}
                        />
                      )}
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 18,
                        fontWeight: "500",
                        color:
                          selectedConnectionDetails?.id === item.id &&
                            !showNewConnection
                            ? "#61CEF7"
                            : "white",
                      }}
                    >
                      {JSON.parse(item.configuration)?.name ?? "Connection"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 24,
                left: 24,
              }}
            >
              <Button
                style={{ width: "248px" }}
                type="primary"
                className="sign-in"
                onClick={() => {
                  setFormMode("NEW");
                  setNewConnectionDetails(JSON.parse(JSON.stringify(defaultConnectionDetails)));
                  setShowNewConnection(true);
                }}
              >
                Add New Connection
              </Button>
            </div>
          </div>
          {showNewConnection ? (
            renderNewDataEndpointForm()
          ) : (
            <div
              style={{
                background: "black",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingLeft: 20,
                paddingTop: 24,
                paddingBottom: 24,
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontFamily: "Montserrat",
                  }}
                >
                  {selectedConnectionConfiguration?.name ?? "Connection"}
                </span>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("EDIT");
                      setShowNewConnection(true);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                      });
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Edit"}
                  </Button>
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("DUPLICATE");
                      setShowNewConnection(true);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                        s3Bucket: "",
                        name: "",
                      });
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Duplicate"}
                  </Button>
                  <img
                    alt="delete"
                    onClick={() => {
                      setSelectedConnectionToDelete(
                        selectedConnectionDetails.id
                      );
                    }}
                    src={require("../../../assets/remove_icon.png")}
                    style={{
                      marginLeft: 16,
                      marginRight: 16,
                      cursor: "pointer",
                      width: 18,
                      height: 20,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Connection Settings
                </span>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"AWS Access Type"}
                    infoText={
                      "lockr recommends using AWS IAM roles for its enhanced security."
                    }
                    value={
                      selectedConnectionConfiguration?.accessType ?? "AWS IAM"
                    }
                  />
                </div>
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Role ARN"}
                        value={selectedConnectionConfiguration?.roleARN ?? ""}
                      />
                    </div>
                  )}
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"External ID"}
                        value={selectedConnectionConfiguration?.externalId ?? ""}
                      />
                    </div>
                  )}
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Session Name"}
                        value={selectedConnectionConfiguration?.sessionName ?? ""}
                      />
                    </div>
                  )}
                {selectedConnectionConfiguration?.accessType ===
                  "AWS Access Key" && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Access Key"}
                        value={selectedConnectionConfiguration?.accessKey ?? ""}
                      />
                    </div>
                  )}
                {selectedConnectionConfiguration?.accessType ===
                  "AWS Access Key" && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Secret Key"}
                        value={selectedConnectionConfiguration?.secretKey ?? ""}
                      />
                    </div>
                  )}
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Bucket Settings
                </span>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Bucket"}
                    value={selectedConnectionConfiguration?.s3Bucket ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Region"}
                    value={selectedConnectionConfiguration?.region ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"File Name"}
                    value={selectedConnectionConfiguration?.fileName ?? ""}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Delivery Settings
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    marginTop: 20,
                    gap: 32,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <DataEndpointTitleValue
                      title={"Delivery Type"}
                      showDropdown={true}
                      value={
                        selectedConnectionConfiguration?.type ===
                          "Complete Dump"
                          ? "Complete"
                          : selectedConnectionConfiguration?.type ?? ""
                      }
                    />
                  </div>
                  {selectedConnectionConfiguration?.type !==
                    "Complete Dump" && (
                      <Button
                        style={{ width: "200px" }}
                        type="primary"
                        size="small"
                        className="data-endpoint-refresh-button"
                        onClick={() => {
                          setShowFullRefreshModal(true);
                        }}
                      >
                        Full Refresh
                      </Button>
                    )}
                  {false &&
                    (displayFullRefreshLoading ||
                      selectedConnectionConfiguration?.status ===
                      "PROCESSING" ||
                      selectedConnectionConfiguration?.status ===
                      "IN_PROGRESS") && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: 48,
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <RotatingLines width="20" strokeColor="white" />
                        <label
                          style={{
                            color: "white",
                            marginTop: 12,
                            fontSize: 12,
                            fontWeight: "400",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Transferring Data
                        </label>
                      </div>
                    )}
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Delivery Frequency"}
                    showDropdown={true}
                    value={selectedConnectionConfiguration?.frequency ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Delivery Status
                  </span>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 8,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: 20,
                        background: mapSnowflakeStatusToColor(
                          selectedConnectionDetails?.status
                        ),
                      }}
                    />
                    <span
                      className="connection-tile-font"
                      style={{
                        marginLeft: 8,
                        color: "white",
                        fontSize: 18,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {mapSnowflakStatusToUI(selectedConnectionDetails?.status)}
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <AppSelectionDropdown disabled={true} value={selectedConnectionConfiguration?.appIDs} onChange={appIDs => {
                  setNewConnectionDetails(prevState => ({
                    ...prevState,
                    appIDs
                  }))
                }}
                  domainsValue={selectedConnectionConfiguration?.domains}
                  onDomainChange={domains => {
                    setNewConnectionDetails(prevState => ({
                      ...prevState,
                      domains,
                    }))
                  }}
                />
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Attributes
                  </span>
                  <span
                    style={{
                      marginTop: 16,
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: 12,
                      color: "#8B8B8B",
                    }}
                  >
                    Select the attributes to include for this connection with
                    the endpoint.
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 32,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.lastName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Last Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.rawEmail}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Email
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.sha256}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Email Hash
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.uid2Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.uid2EnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5EnabledHistory}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (history)
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.liverampEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.liverampEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.euidEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    EUID (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.euidEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    EUID (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.yahooConnectIdEnabled
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Yahoo Connect ID (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.yahooConnectIdEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Yahoo Connect ID (history)
                  </span>
                </div>
                <div style={{ marginTop: 20, paddingLeft: 32 }}>
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstIdEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First ID (latest)
                  </span>
                </div>
                <div style={{ marginTop: 20, paddingLeft: 32 }}>
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstIdEnabledHistory}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First ID (history)
                  </span>
                </div>
                <div style = {{
                    marginTop: 20,
                    paddingLeft: 32,
                }}>
                    <Checkbox
                        checked={selectedConnectionConfiguration?.panoramaIdEnabled}
                        disabled
                    />
                    <span style = {{
                        color: 'white',
                        fontFamily: "Montserrat",
                        marginLeft: 12,
                        fontSize: 16,
                        fontWeight: "500"
                    }}>
                        Panorama ID (latest)
                    </span>
                </div>
                <div style = {{
                    marginTop: 20,
                    paddingLeft: 32
                }}>
                    <Checkbox disabled checked={selectedConnectionConfiguration?.panoramaIdEnabledHistory}/>
                    <span style = {{
                        color: 'white',
                        fontFamily: 'Montserrat',
                        marginLeft: 12,
                        fontSize: 16,
                        fontWeight: "500"
                    }}>
                        Panoarama ID (history)
                    </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstPartyCookies}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Party Cookies
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.successEvent}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Authentication Method
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.consentString}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    TCF String
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.dateCreatedEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Created At
                  </span>
                </div>

              </div>
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }

  function renderEmptySection() {
    if (!displayLoading && awsConnections.length <= 0 && !showNewConnection) {
      return (
        <div
          style={{
            marginLeft: 90,
            marginRight: 90,
            minHeight: "50vh",
            background: "black",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="logo_grey" src={require("../../../assets/logo_grey.png")} />
          <div
            style={{
              marginTop: 42,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography.Text
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                color: "#fff",
              }}
            >
              Connect lockr with Optable
            </Typography.Text>
            <Button
              style={{ marginTop: 24, width: "217px" }}
              type="primary"
              className="btn-first-connection"
              onClick={() => {
                setFormMode("NEW");
                setNewConnectionDetails(defaultConnectionDetails);
                setShowNewConnection(true);
              }}
            >
              Add New Connection
            </Button>
          </div>
        </div>
      );
    }
  }

  async function deleteOptableEndpoint() {
    try {
      await deleteAWSEndpoint(selectedConnectionToDelete);
      setSelectedConnectionToDelete(undefined);
      getS3EndpointsCall();
    } catch (error) {
      console.log('Unable to delete the optable endpoint at the moment');
      console.log(error);
    }
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={17} xs={24}>
          <Typography.Title className="apiandservices-heading">
            Optable Data Endpoint
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Use lockr's integration with Optable to deliver audience data to plan, activate and measure campaigns.
            </label>
          </div>
        </Col>
        <Col sm={7} xs={24} className="createAppButton">
          <Button
            style={{ width: "248px" }}
            type="primary"
            className="sign-in"
            onClick={() => {
              setFormMode("NEW");
              setNewConnectionDetails(defaultConnectionDetails);
              setShowNewConnection(true);
            }}
          >
            Add New Connection
          </Button>
        </Col>
      </Row>
      {renderEmptySection()}
      {renderMainSections()}
      {!!selectedConnectionToDelete && (
        <DeleteTypeModal
          visible={!!selectedConnectionToDelete}
          onCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          title="Delete Connection"
          message={`Are you sure you want to delete this Optable connection? Doing so will disable all future deliveries to this bucket.`}
          handleCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          handleOk={() => {
            deleteOptableEndpoint();
          }}
        />
      )}
      <FullRefreshNoticeModal
        display={showFullRefreshModal}
        onCancel={() => {
          setShowFullRefreshModal(false);
        }}
        onOk={() => {
          startFullRefresh();
        }}
      />
      <ReturnToConnectionHubModal
        display={showFullRefreshIndicator}
        onOk={() => {
          setShowFullRefreshIndicator(false);
          history.push("/console/connections");
        }}
      />
      {showFullRefreshIndicator && (
        <FullRefreshLoader
          onClose={() => {
            setShowFullRefreshIndicator(false);
          }}
          dataConfigurationId={selectedConnectionDetails?.id}
        />
      )}
    </SideDrawer>
  );
}
